import logo from './logo.svg';
import './App.css';

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import LandingPage from './LandingPage';

function Home() {
  return <h1>Home Page</h1>;
}

function JsonPage() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch('/ontario_test_questions.json')
      .then((response) => response.json())
      .then((jsonData) => setData(jsonData));
  }, []);

  return (
    <div>
      <h1>JSON Data</h1>
      {data ? <pre>{JSON.stringify(data, null, 2)}</pre> : <p>Loading...</p>}
    </div>
  );
}

function App() {

  const linkStyle = {
    color: 'blue',
    textDecoration: 'none',
    padding: '8px 16px',
    borderRadius: '4px',
    transition: 'background-color 0.3s, color 0.3s',
    backgroundColor: 'pink'
  };

  const activeStyle = {
    backgroundColor: '#007BFF',
    color: 'Pink',
  };
  return (
    <Router>
      <nav>
        <Link style={linkStyle} activeStyle={activeStyle} to="/">Home</Link> 
        | <Link style={linkStyle} activeStyle={activeStyle} to="/privacyPolicy"> Privacy Policy</Link>
      </nav>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/json" element={<JsonPage />} />
        <Route path="/privacyPolicy" element = {<PrivacyPolicy />}/>
      </Routes>
          </Router>
  );
}

export default App;
